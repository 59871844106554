export const MenuRecuperi = [
    {
        path: '/',
        icon: 'home',
        text: 'Início',
        authRequired: true,
        hide: ['usuarioLite']
    },
    {
        path: '/upload-titulos',
        icon: 'cloud_upload',
        text: 'Upload Títulos',
        authRequired: true,
        hide: ['usuarioLite']
    },
    {
        path: '/consulta-titulos',
        icon: 'fas fa-search',
        text: 'Consulta de Títulos',
        authRequired: true
    },
    {
        path: '/download-instrumentos',
        icon: 'mdi-download',
        text: 'Baixar Instrumentos',
        authRequired: true
    },
    {
        path: '/alteracoes-em-lote',
        icon: 'mdi-file-sync-outline',
        text: 'Alterações em Lote',
        authRequired: true,
        hide: ['usuarioLite'],
        childs: [
            {
                path: '/cancelamento-em-massa',
                icon: '',
                text: 'Cancelamento',
                authRequired: true,
                hide: ['usuarioAdmin notSuper', 'usuarioBasic']
            },
            {
                path: '/anuencia-em-massa',
                icon: '',
                text: 'Anuência',
                authRequired: true,
                hide: ['usuarioBasic']
            },
            {
                path: '/desistencia-em-massa',
                icon: '',
                text: 'Desistência',
                authRequired: true,
                hide: ['usuarioAdmin notSuper', 'usuarioBasic']
            },
            {
                path: '/agrupador-em-massa',
                icon: '',
                text: 'Agrupador',
                authRequired: true,
                hide: ['usuarioBasic']
            },
            {
                path: '/upload-em-massa',
                icon: '',
                text: 'Upload Arquivo Protesto',
                authRequired: true
            },
            {
                path: '/revisao-interna',
                icon: '',
                text: 'Revisão Interna',
                authRequired: true
            }
        ]
    },
    {
        path: '/relatorios',
        icon: 'assignment',
        text: 'Relatórios',
        authRequired: true,
        childs: [
            {
                path: '/relatorios-mensageria',
                icon: '',
                text: 'Mensageria',
                authRequired: true
            },
            // {
            //     path: '/exceldetalhado',
            //     icon: '',
            //     text: 'Detalhado Títulos',
            //     authRequired: true
            // },
            {
                path: '/financeiro/repasse',
                icon: '',
                text: 'Repasses e Taxas',
                authRequired: true,
                hide: ['usuarioLite']
            },
            {
                path: '/relatorios/ofertas-globais',
                icon: '',
                text: 'Ofertas Globais',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/relatorios/ofertas-titulo',
                icon: '',
                text: 'Ofertas Título',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/relatorios/campanhas-sms',
                icon: '',
                text: 'Campanhas SMS',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/relatorios/campanhas-whatsapp',
                icon: '',
                text: 'Campanhas WhatsApp',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/relatorios-faturamento',
                icon: '',
                text: 'Relatorio Faturamento',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            }
        ]
    },
    {
        path: '/mensageria',
        icon: 'message',
        text: 'Campanhas',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper'],
        childs: [
            {
                path: '/campanhas-whatsapp',
                icon: '',
                text: 'Campanhas WhatsApp',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/enviar-email',
                icon: '',
                text: 'Campanhas E-mail',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            },
            {
                path: '/enviar-sms',
                icon: '',
                text: 'Campanhas SMS',
                authRequired: true,
                hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
            }
        ]
    },
    {
        path: '/central-ofertas',
        icon: 'fas fa-percent',
        text: 'Ofertas/Renegociações',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper'],
        childs: [
            {
                path: '/ofertas-globais',
                icon: '',
                text: 'Ofertas Globais',
                authRequired: true
            },
            {
                path: '/ofertas-titulo',
                icon: '',
                text: 'Ofertas Título',
                authRequired: true
            },
            {
                path: '/renegociacoes',
                icon: '',
                text: 'Renegociações',
                authRequired: true
            }
        ]
    },
    {
        path: '/listar-usuarios',
        icon: 'people',
        text: 'Listar Usuários',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic']
    },
    {
        path: '/listar-apresentantes',
        icon: 'people',
        text: 'Listar Apresentantes',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
    },
    {
        path: '/listar-agrupadores',
        icon: 'mdi-account-group',
        text: 'Listar Agrupadores',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic']
    },
    {
        path: '/listar-comarcas',
        icon: 'fas fa-map-signs',
        text: 'Comarcas',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
    },
    {
        path: '/listar-cartorios',
        icon: 'fas fa-building',
        text: 'Cartórios',
        authRequired: true,
        hide: ['usuarioLite', 'usuarioBasic', 'usuarioAdmin notSuper']
    },
    {
        path: '/mapa-de-status',
        icon: 'map',
        text: 'Mapa de Títulos',
        authRequired: true,
        hide: ['usuarioLite']
    },
    {
        path: '/manuais',
        icon: 'cloud_download',
        text: 'Manuais',
        authRequired: true,
        hide: ['usuarioLite'],
        childs: [
            {
                path: '/fluxo-de-protesto',
                icon: '',
                text: 'Fluxo de Protesto',
                authRequired: true
            },
            {
                path: '/manual-do-usuario',
                icon: '',
                text: 'Manual do usuário',
                authRequired: true
            },
            {
                path: '/manual-do-protesto',
                icon: '',
                text: 'Manual do protesto',
                authRequired: true
            },
            {
                path: '/manual-de-upload',
                icon: '',
                text: 'Manual de Upload',
                authRequired: true
            },
            {
                path: '/documentacao-api',
                icon: '',
                text: 'Documentação de API',
                authRequired: true
            }
        ]
    },
    {
        path: '/emolumentos',
        icon: 'info',
        text: 'Emolumentos',
        authRequired: true,
        hide: ['usuarioAdmin notSuper', 'usuarioBasic', 'usuarioLite']
    },
    {
        path: '/faq',
        icon: 'info',
        text: 'Dúvidas Frequentes',
        authRequired: true,
        hide: ['usuarioLite']
    },
    {
        path: '/sobre',
        icon: 'info',
        text: 'Sobre',
        authRequired: true,
        hide: ['usuarioLite']
    }
];

export default MenuRecuperi;
