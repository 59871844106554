import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import router from '@/router';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'recuperi-front-vue',
    storage: window.localStorage
});

export default new Vuex.Store({
    state: {
        drawer: false,
        user: null,
        token: null,
        isAuthenticated: false,
        titulo: {},
        situacoesTitulo: [],
        listaAgrupadores: [],
        listaAgrupadoresFiltro: [],
        listaTiposApresentante: [],
        reloadDetalhesTitulo: false,
        tokenExpirou: null,
        itensMunicipios: {},
        abortCtrl: null,
        isUserReadonly: false,
        notificaCompanyUuid: null,
        aceitouModal: false,
        listaIrregularidades: [],
        versionBeta: false
    },
    mutations: {
        setTokenExpired(state, payload) {
            state.tokenExpirou = payload;
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setApresentante(state, payload) {
            state.user.cd_apresentante = payload;
        },
        setNomeApresentante(state, payload) {
            state.user.nm_apresentante = payload;
        },
        setNomeExibicaoApresentante(state, payload) {
            state.user.nome_exibicao = payload;
        },
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        },
        setNotificaCompanyUuid(state, payload) {
            state.notificaCompanyUuid = payload;
        },
        setUserCraNacional(state, payload) {
            state.user.cra_nacional = payload;
        },
        setSituacoesTitulo(state, payload) {
            state.situacoesTitulo = payload;
        },
        setListaIrregularidades(state, payload) {
            state.listaIrregularidades = payload;
        },
        setListaAgrupadores(state, payload) {
            state.listaAgrupadores = payload;
        },
        setListaAgrupadoresFiltro(state, payload) {
            state.listaAgrupadoresFiltro = payload;
        },
        setListaTiposApresentante(state, payload) {
            state.listaTiposApresentante = payload;
        },
        setCarteiras(state, payload) {
            state.Carteiras = payload;
        },
        setTitulo(state, payload) {
            state.titulo = payload;
        },
        setItensMunicipios(state, payload) {
            state.itensMunicipios = payload;
        },
        setAbortCtrl(state, payload) {
            state.abortCtrl = payload;
        },
        setIsReadonly(state, payload) {
            state.isUserReadonly = payload;
        },
        setAceitouModal(state, payload) {
            state.aceitouModal = payload;
        },
        setVersionBeta(state, payload) {
            state.versionBeta = payload;
        }
    },
    actions: {
        userLogin({ commit }, { email, password }) {
            return new Promise((resolve, reject) => {
                Vue.http
                    .post('login', { username: email, password: password })
                    .then(
                        response => {
                            response.status;
                            if (response.status === 200) {
                                let user = response.body.body;
                                localStorage.setItem(
                                    'ds_identidade_visual',
                                    user.ds_identidade_visual
                                );
                                commit('setUser', {
                                    ds_identidade_visual: user.ds_identidade_visual
                                        ? JSON.parse(
                                              user.ds_identidade_visual.replace(
                                                  /'/g,
                                                  '"'
                                              )
                                          )
                                        : null,
                                    cra_nacional: user.cra_nacional,
                                    ds_agrupador: user.ds_agrupador,
                                    link_contato: user.link_contato,
                                    nome_exibicao: user.nome_exibicao,
                                    nm_apresentante: user.nm_apresentante,
                                    cd_apresentante: user.cd_apresentante,
                                    id_user: user.id_user,
                                    name: user.nm_user,
                                    email: user.ds_email,
                                    login: user.ds_login,
                                    status:
                                        user.st_ativo === 1
                                            ? 'ATIVO'
                                            : 'INATIVO',
                                    perfil: user.tp_perfil,
                                    dataCadastro: user.dt_cadastro, // FIXME converter para date
                                    is_superuser: user.is_superuser,
                                    is_admin: user.is_admin,
                                    is_lite: user.is_lite,
                                    is_lite_readonly: user.is_lite_readonly,
                                    partner_id: user.partner_id
                                });
                                const isUserReadonly =
                                    user.is_lite_readonly === 1 ? true : false;
                                commit('setToken', response.body.access_token);
                                commit('setIsAuthenticated', true);
                                commit(
                                    'setNotificaCompanyUuid',
                                    user.notifica_company_uuid
                                );
                                commit('setIsReadonly', isUserReadonly);
                            } else {
                                console.log(response.body.msg);
                            }
                            resolve(response);
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        },
        setVersaoBeta({ commit }, data) {
            commit('setVersionBeta', data);
        },
        mudarApresentante({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                Vue.http
                    .post('alterar_cd_apresentante', { cd_apresentante: data })
                    .then(
                        response => {
                            response.status;
                            if (response.status === 200) {
                                commit('setApresentante', data);
                                commit(
                                    'setNomeApresentante',
                                    response.body.apresentante.nm_razao_social
                                );
                                commit(
                                    'setNomeExibicaoApresentante',
                                    response.body.apresentante.nome_exibicao
                                );
                                commit('setToken', response.body.access_token);
                                commit('setIsAuthenticated', true);
                                commit(
                                    'setNotificaCompanyUuid',
                                    response.body.apresentante
                                        .notifica_company_uuid
                                );
                                commit(
                                    'setUserCraNacional',
                                    response.body.apresentante.cra_nacional
                                );
                                commit('setListaAgrupadores', []);
                                commit('setListaIrregularidades', []);
                                commit('setListaAgrupadoresFiltro', []);
                                commit('setListaTiposApresentante', []);
                                router.push('/');
                                window.location.reload();
                            } else {
                                console.log(response.body.msg);
                            }
                            resolve(response);
                        },
                        error => {
                            console.log(error);
                            reject(error);
                        }
                    );
            });
        },
        userLogout({ commit, getters }) {
            Vue.http
                .post('logout', {
                    username: getters.user.email,
                    cd_apresentante: getters.user.cd_apresentante
                })
                .then(
                    response => {
                        response.status;
                    },
                    error => {
                        console.log(error);
                    }
                );
            commit('setToken', null);
            commit('setNotificaCompanyUuid', null);
            commit('setTokenExpired', false);
            commit('setUser', null);
            commit('setIsAuthenticated', false);
            commit('setIsReadonly', false);
            commit('setSituacoesTitulo', []);
            commit('setListaAgrupadores', []);
            commit('setListaIrregularidades', []);
            commit('setListaAgrupadoresFiltro', []);
            commit('setListaTiposApresentante', []);
            commit('setAceitouModal', false);
            localStorage.removeItem('filtroTitulos');
            localStorage.removeItem('resultadoTitulos');
            // commit('setItensMunicipios', {});
        },
        sessaoExpirou({ commit, getters }) {
            Vue.http
                .post('logout', {
                    username: getters.user.email,
                    cd_apresentante: getters.user.cd_apresentante
                })
                .then(
                    response => {
                        response.status;
                    },
                    error => {
                        console.log(error);
                    }
                );
            commit('setToken', null);
            commit('setTokenExpired', true);
            commit('setUser', null);
            commit('setIsAuthenticated', false);
            commit('setSituacoesTitulo', []);
            commit('setListaAgrupadores', []);
            commit('setListaIrregularidades', []);
            commit('setListaAgrupadoresFiltro', []);
            commit('setListaTiposApresentante', []);
            commit('setAceitouModal', false);
        }
    },
    modules: {},
    getters: {
        isBetaVersion(state) {
            return state.versionBeta;
        },
        isAuthenticated(state) {
            return state.user !== null && state.user !== undefined;
        },
        isUserReadonly(state) {
            return state.isUserReadonly;
        },
        aceitouModal(state) {
            return state.aceitouModal;
        },
        userRoles(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.roles;
            }
            return [];
        },
        user(state) {
            if (state.user !== null && state.user !== undefined) {
                //console.log(state.user);
                return state.user;
            }
            return null;
        },
        isUsuarioAgrupador(state) {
            if (state?.user && state?.user?.ds_agrupador != null) {
                return true;
            }
            return false;
        },
        isApresentanteCraNacional(state) {
            if (state?.user?.cra_nacional) {
                return true;
            }
            return false;
        },
        token(state) {
            if (state.token !== null && state.token !== undefined) {
                return state.token;
            }
            return null;
        },
        situacoesTitulo(state) {
            return state.situacoesTitulo;
        },
        listaIrregularidades(state) {
            return state.listaIrregularidades;
        },
        listaAgrupadores(state) {
            return state.listaAgrupadores;
        },
        listaAgrupadoresFiltro(state) {
            return state.listaAgrupadoresFiltro;
        },
        listaTiposApresentante(state) {
            return state.listaTiposApresentante;
        },
        Carteiras(state) {
            return state.Carteiras;
        },
        idUser(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.id_user;
            }
            return null;
        },
        agrupadorUsuario(state) {
            if (state.user?.ds_agrupador && state.user?.ds_agrupador !== '') {
                return state.user.ds_agrupador;
            }
            return null;
        },
        cdApresentante(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.cd_apresentante;
            }
            return null;
        },
        apresentanteNotifica(state) {
            if (state?.notificaCompanyUuid != null) {
                return state.notificaCompanyUuid;
            }
            return null;
        },
        NomeExibicaoApresentante(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.nome_exibicao;
            }
            return null;
        },
        nomeApresentante(state) {
            if (state.user) {
                return state.user.nm_apresentante;
            }
            return null;
        },
        getUserName(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.name;
            }
            return null;
        },
        getUserLogin(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.login;
            }
            return null;
        },
        getUserEmail(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.email;
            }
            return null;
        },
        userType(state) {
            if (!state.user) return '';
            if (!state.user.partner_id  && state.user.is_superuser ) {
                return 'superAdminRecuperi';
            } else if (state.user.is_lite) {
                return 'lite';
            } else if (state.user.is_lite_readonly) {
                return 'liteRo';
            } else if (state.user.partner_id  && state.user.is_superuser) {
                return 'superAdminGeral';
            } else if (state.user.is_admin) {
                return 'admin';            
            } else {
                return 'basic';
            }
        },
        isBasicUser(state) {
            if (!state.user) return false;
            if (
                state.user.is_admin === 0 &&
                state.user.is_superuser === 0 &&
                state.user.is_lite === 0 &&
                state.user.is_lite_readonly === 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        getSuperUser(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.is_superuser;
            }
            return null;
        },
        getTitulo(state) {
            if (state.titulo !== null && state.titulo !== undefined) {
                return state.titulo;
            }
            return null;
        },
        getAbortCtrl(state) {
            if (state.abortCtrl) {
                return state.abortCtrl;
            }
            return null;
        },
        isAdmin(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.is_admin;
            }
            return null;
        },
        isSuperAdminRecuperi(state) {
            if (state.user && !state.user.partner_id  && state.user.is_superuser) {
                return true
            }
            return false;
        },
        isPartner(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.partner_id;
            }
            return null;
        },
        isLite(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.is_lite;
            }
            return null;
        },
        isLiteRo(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.is_lite_readonly;
            }
            return null;
        },
        isTypeUser(state) {
            if (state.user !== null && state.user !== undefined) {
                return state.user.perfil;
            }
            return null;
        },
        tokenExpired(state) {
            if (
                state.tokenExpirou !== null &&
                state.tokenExpirou !== undefined
            ) {
                return state.tokenExpirou;
            }
            return null;
        },
        itensMunicipios(state) {
            if (state.itensMunicipios) {
                return state.itensMunicipios;
            }
            return {};
        },
        listaMunicipios(state) {
            if (
                state.itensMunicipios &&
                state.itensMunicipios.municipios &&
                state.itensMunicipios.municipios.length > 0
            ) {
                return state.itensMunicipios.municipios;
            }
            return [];
        }
    },
    plugins: [vuexLocalStorage.plugin]
});
